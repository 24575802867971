canvas {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.cones{
  height: 100vh !important;
  width: 100vw !important;
}