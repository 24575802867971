
.slider-cont{
    width: 100%;
    display: flex;
    padding: 1pc;
}
.swiper-container {
    position: relative;
    width: 100%;
    max-width: 70vw;
    max-height: 100vh;
    min-height: 40vh;
    min-width: 0;
    overflow: hidden;
}

.swiper-wrapper {
    max-height: 100%;
    height: 100%;
    display: flex;
}

.swiper-container>.swiper-slide__content {
    position: absolute;
    top: 0;
}


.swiper-slide {
    transform: scale(0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2pc;
}

.swiper-slide .round-item {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    overflow: hidden;
}

.swiper-slide-active {
    transform: scale(1);
    transition: 0.3s;
}

.swiper-slide:hover{
    cursor: pointer;
}

.swiper-slide-active .round-item {
    border: 3px solid #FFFFFF;
}

.swiper-slide:hover .round-item {
    border: 3px solid #FFFFFF;
    /* transition: all 3ms;
    transition-timing-function: cubic-bezier(0.64, 0.04, 0.35, 1); */
}

.swiper-slide:hover .round-item img{
    opacity: 1;
}

.swiper-slide .round-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.4;
}

.swiper-slide-active .round-item img {
    opacity: 1;
}

.naturescape-name{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 69%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    padding-bottom: 0.5pc;
}
.swiper-button-prev {
    color: white !important;
}

.swiper-button-next {
    color: white !important;
}

.arrow-container{
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.slider-back{
    position: absolute;
    top: 100px;
    left: 10vw;
    right: auto;
}

.slider-next{
    position: absolute;
    top: 100px;
    left: auto;
    right: 10vw;
}

@media screen and (min-height: 800px) {
    .slider-back, .slider-next{
        top: 120px !important;
    }
}


@media screen and (min-width: 1300px) and (max-width: 1500px) {

    .swiper-container {
        max-width: 80vw;
    }
    .slider-back{
        position: absolute;
        top: 90px;
        left: 5vw;
        right: auto;
    }
    
    .slider-next{
        position: absolute;
        top: 90px;
        left: auto;
        right: 5vw;
    }
    
}
@media screen and (max-width: 1300px) {

    .swiper-container {
        max-width: 80vw;
    }
    .slider-back{
        position: absolute;
        top: 90px;
        left: 2vw;
        right: auto;
    }
    
    .slider-next{
        position: absolute;
        top: 90px;
        left: auto;
        right: 2vw;
    }
    
}
@supports (not(-webkit-touch-callout: none)) and (not(translate:none)){

    .swiper-slide .round-item{
        margin-bottom: 2pc;
    }
    .swiper-slide-active .round-item{
        margin-bottom: 2pc;
    }
  }