  /* #text-focus {
    font-size: 6vh;
    color: white;
    text-align: center;
    font-family: Helvetica, sans-serif;
  }
  #text-relax {
    font-size: 6vh;
    color: white;
    text-align: center;
    font-family: Helvetica, sans-serif;
    margin-left: 5px;
    margin-right: 5px;
  } */
  #text {
    font-size: 6vh;
    color: white;
    text-align: center;
    font-family: Helvetica, sans-serif;
  }
  #start-message {
    font-size: 3vh;
    color: white;
    font-family: Helvetica, sans-serif;
  }
  /*Notice setting margin to auto*/
  .breath-container{
    position: relative;
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(1);
    margin: auto;
  }
  
  /*Use the taper gradient as the background, the aspect ratio of. Container is slightly larger, and the Z-index should be set to - 2, because there is also a layer of. Circle, and the outermost layer is text*/
  .gradient-circle-focus{
    position: absolute;
    /* left: -10px;
    top: -10px; */
    background: rgba(255, 0, 255, 0);
    width: 220px;
    height: 220px;
    /* border-radius: 50%; */
    z-index: -2;
  }
  .gradient-circle-relax{
    position: absolute;
    left: -10px;
    top: -10px;
    background: rgba(0, 0, 0, 0);
    width: 220px;
    height: 220px;
    border-radius: 50%;
    z-index: -2;
  }
  /*. The Z-index is - 1, which is a black circle in the middle*/
  .circle{
    position: absolute;
    left: 0;
    top: 0;
    width: 200px;
    height: 200px;
    background-image: url("../assets/icons/img_glass_dark_circle_v2.png");
    /* background-color: indigo; */
    /* border-radius: 50%; */
    z-index: -1;
  }

  .texty span {
    font-size: 2vh;
    color: white;
    display: block;
    font-family: Helvetica, sans-serif;
    padding-left: 5px;
    padding-right: 5px;
  }

  #top-text {
    font-size: 4vh;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -80%);
  }
  
 .breath-container.grow{
    animation: grow 5s linear forwards;
  }
  .breath-container.shrink{
    animation: shrink 7.5s linear forwards;
  }
  @keyframes grow{
    from{
      transform: scale(1)
    }to{
      transform: scale(1.5);
    }
  }
  
  @keyframes shrink{
    from{
      transform: scale(1.5)
    }to{
      transform: scale(1);
    }
  }