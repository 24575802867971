.App {
  text-align: center;
}

#wigglysquiggly {
  width: 100vw;
  height: 100vh;
  background-color: #303030;
}

