.choose-zense-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.zense-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  width: 600px;
  margin-top: 20px;
}

.header-row {
  display: flex;
  width: 100%;
  padding-bottom: 4pc;
}

.header-row-login {
  display: flex;
  width: 100%;
  height: 70px;
  padding-bottom: 2pc;
}

.header-row-subscribe {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 1pc;
  padding-bottom: 1pc;
}

.header-row-welcome {
  display: flex;
  /* flex-direction:; */
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 1pc;

  padding-bottom: 1pc;
}

.header-row-review {
  display: flex;
  width: 100%;
  padding-top: 1pc;

  padding-bottom: 1pc;
}

.header-row-choose {
  display: flex;
  width: 100%;
  padding-bottom: 2pc;
}

.logo-container {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.logo-container-subscribe {
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
}


.box-row-zense {
  margin-top: 3%;
  display: flex;
  flex-direction: row;
  column-gap: 5%;
  width: 85%;
}

.box-row-zense:focus-visible {
  outline: none;
}

.zense-box {
  background: linear-gradient(152.97deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%);
  /* backdrop-filter: blur(25.9976px); */
  border-radius: 9.90385px;
  border-top: 2px solid rgba(225, 225, 225, 0.3);
  border-bottom: 1px solid rgba(225, 225, 225, 0.3);
  border-left: 2px solid rgba(225, 225, 225, 0.1);
  border-right: 1px solid rgba(225, 225, 225, 0.2);
  flex-basis: 100%;
  min-height: 220px;
  padding: 1% 0 4% 0;
  display: flex;
  flex-direction: column;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  /* row-gap: 20px; */
  cursor: pointer;
  position: relative;
}

.zense-box-vector {
  width: 164px;
  height: 164px;
}

.zense-box:hover {
  opacity: 0.8;
}

.zense-box-subheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 50px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffffff;
}

.major-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.major-heading-page-not-found {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  width: 80vw;
}

.zense-box-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100% !important;
  width: 100%;
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  align-items: center;
  row-gap: 1pc;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  /* padding: 1% 0 4% 0; */
}

.zense-box-back-body {
  font-family: "Poppins";
  font-style: normal;
  font-size: 17px;
  line-height: 130%;
  display: flex;
  flex-direction: column;
  display: absolute;
  align-items: left;
  justify-content: left;
  text-align: left;
  color: #ffffff;
}

.zense-box-back-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 165%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffffff;
}

.touch-zense-warning-text {
  font-size: 12px;
  margin-top: 1pc;
  font-family: "Poppins";
  font-style: normal;
  line-height: 130%;
  display: flex;
  flex-direction: column;
  display: absolute;
  align-items: left;
  justify-content: left;
  text-align: left;
  color: #ffffff;
  margin-bottom: 0.5pc;
}

.zense-box-front,
.zense-box-back {
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
  perspective: 0;
  -webkit-perspective: 0;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  visibility: visible;
  display: flex;
  flex-direction: column;
  row-gap: 2pc;
}

.zense-box.active {
  min-height: 370px;
  padding: 1% 0 4% 0 !important;
}

.zense-box.active .zense-box-inner {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  width: 75%;
  margin-top: 1pc;
  justify-content: center;
}

@supports (not (-webkit-touch-callout: none)) and (not (translate: none)) {
  @media screen and (min-width: 1024px) {
    .zense-box {
      margin-right: 1pc;
      margin-left: 1pc;
    }
  }

  @media screen and (max-width: 786px) {
    .zense-box {
      margin-bottom: 1pc;
      margin-top: 1pc;
      margin-right: 0;
      margin-left: 0;
    }
  }
}

@media screen and (max-height: 700px) and (min-width: 1024px) {
  .header-row-welcome {
    padding-bottom: 2pc !important;
  }
}

@media screen and (max-width: 1200px) and (min-width: 1024px) {

  /* .zense-box.active {
    overflow: auto;
  } */
  /* .zense-box.active{
    padding-bottom: 0;
    min-height: 275px;
  } */
  .zense-box-back-body {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 1200px) {
  .zense-box-vector {
    width: 130px;
  }

  .zense-box-back-body {
    font-size: 12px;
  }

  .zense-box.active {
    /* min-height: 320px; */
    padding: 1% 0 2% 0 !important;
    min-height: 280px;
  }

  .touch-zense-warning-text {
    font-size: 10px;
  }
}

@media screen and (max-width: 786px) {
  .header-row {
    padding-bottom: 1pc;
  }

  .box-row-zense {
    margin-top: 3%;
    display: flex;
    flex-direction: column !important;
    row-gap: 5%;
    height: 75%;
    width: 80% !important;
    align-items: center;
    overflow-y: scroll;
    margin-bottom: 3%;
  }

  .zense-box {
    flex-basis: 100%;
    width: 90% !important;
    height: auto !important;
    padding-top: 5%;
  }

  .zense-box-vector {
    width: 100px;
    height: 100px;
  }

  .zense-box.active {
    flex-grow: 1;
    /* flex-shrink: 0.1; */
    overflow: auto;
    min-height: 240px;
    padding: 1% 0 4% 0 !important;
  }

  .zense-box-inner {
    height: auto !important;
  }

  .zense-box img {
    width: auto;
  }

  .zense-heading {
    width: auto;
    font-size: 20px;
    line-height: 20px;
    padding: 3%;
  }

  .zense-box-back-body {
    font-size: 15px;
  }
}

@media screen and (max-width: 640px) {

  .login-heading,
  .major-heading,
  .major-heading-page-not-found {
    font-size: 32px;
    line-height: 28px;
  }

  .zense-heading {
    font-size: 17px;
    line-height: 20px;
  }

  .zense-box-back-body {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {

  .login-heading,
  .major-heading,
  .major-heading-page-not-found {
    font-size: 24px;
    line-height: 24px;
  }

  .zense-box-back-body {
    font-size: 12px;
  }

  .zense-box.active {
    min-height: 250px;
  }
}

.empty30 {
  width: 30%;
}