$white: #eee;
$black: #000000;

$width: 40px;

body{
  background-color: white;
}

.loader{
  position: absolute;
  width: $width;
  height: $width;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.circular{
  animation: rotate 2s linear infinite;
  height: $width;
  position: relative;
  width: $width;
}

.path {
  stroke-dasharray: 1,200;
  stroke-dashoffset: 0;
  stroke:#B6463A;
  animation:
   dash 1.5s ease-in-out infinite,
   color 6s ease-in-out infinite
  ;
  stroke-linecap: round;
}

@keyframes rotate{
 100%{
  transform: rotate(360deg);
 }
}
@keyframes dash{
 0%{
  stroke-dasharray: 1,200;
  stroke-dashoffset: 0;
 }
 50%{
  stroke-dasharray: 89,200;
  stroke-dashoffset: -35;
 }
 100%{
  stroke-dasharray: 89,200;
  stroke-dashoffset: -124;
 }
}
@keyframes color{
  100%, 0%{
    stroke: $black;
  }
  40%{
    stroke: $black;
  }
  66%{
    stroke: $black;
  }
  80%, 90%{
    stroke: $black;
  }
}