@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");

.profile-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 20px;
  padding: 10px;
  row-gap: 20px;
}

.profile-content-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: "Poppins";
}

.profile-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  display: flex;
  flex-basis: 10%;
  align-items: flex-end;
  text-align: center;
  color: #ffffff;
  padding-bottom: 0.5pc;
  column-gap: 15px;
  padding-top: 1pc;
  margin: auto;
}

input {
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 16px;
  height: 58px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  color: #ffffff;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  display: flex;
  padding-left: 1pc;
}

input:hover,
input:focus {
  border: 3px solid #ffffff;
}

input:focus-visible {
  outline: none;
}

.input-container {
  margin: auto;
  margin-top: 1pc;
  width: 400px;
  display: flex;
  flex-direction: column;
  row-gap: 1pc;
  align-items: center;
}

.input-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1pc;
  position: relative;
}

.input-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 120%;
  /* identical to box height, or 34px */
  display: flex;
  align-items: center;

  color: #ffffff;
}

.text-danger {
  width: 100%;
  /* height: 40px; */
  min-height: 40px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  /* or 24px */
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ad0015;
  background-color: white;
}

.password-toggle {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  bottom: 20px;
  cursor: pointer;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}

.info-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: 1pc;
  row-gap: 1pc;
}

@media screen and (max-width: 480px) {
  .input-container {
    margin: auto;
    margin-top: 1pc;
    width: 300px;
    row-gap: 1pc;
  }

  input {
    height: 48px;
    font-size: 15px;
  }
}

@media screen and (max-width: 375px) {
  .input-container {
    margin: auto;
    margin-top: 1pc;
    width: 250px;
    row-gap: 1pc;
  }
}

.profile-main-content {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 45%;
}

.profile-box {
  border: 1px solid white;

  border-image-source: linear-gradient(155.31deg,
      rgba(255, 255, 255, 0.5) 12.33%,
      rgba(255, 255, 255, 0) 34.31%,
      rgba(255, 255, 255, 0) 52.66%,
      rgba(255, 255, 255, 0.54) 74.67%);

  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(35px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 16px;

  padding: 16px;

  display: flex;
  flex-direction: column;
}

.profile-box-user {
  /* width: 30%; */

  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  color: white;

  border: 1px solid white;

  border-image-source: linear-gradient(155.31deg,
      rgba(255, 255, 255, 0.5) 12.33%,
      rgba(255, 255, 255, 0) 34.31%,
      rgba(255, 255, 255, 0) 52.66%,
      rgba(255, 255, 255, 0.54) 74.67%);

  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(35px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 16px;

  padding: 16px;
}

.profile-box-empty {
  border: 1px solid white;

  border-image-source: linear-gradient(155.31deg,
      rgba(255, 255, 255, 0.5) 12.33%,
      rgba(255, 255, 255, 0) 34.31%,
      rgba(255, 255, 255, 0) 52.66%,
      rgba(255, 255, 255, 0.54) 74.67%);

  /* Note: backdrop-filter has minimal browser support */

  border-radius: 16px;

  padding: 16px;
}

.profile-box-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 20px;
  /* identical to box height, or 91% */

  display: flex;
  align-items: center;

  color: #ffffff;

  text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.15);
}

.days-container {
  padding-top: 20px;
  display: flex;
  column-gap: 10px;
  justify-content: space-evenly;
}

.slider-container {
  padding: 10px 20px;
  column-gap: 10px;
  width: 100%;
  /* background-color: red; */
  align-self: center;
}

.one-day {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
  gap: 7.4px;
  width: 50px;
  height: 70px;
  background: #ffffff;
  border-radius: 71.6656px;
}

.red-streak-dot {
  width: 12.34px;
  height: 12.34px;

  border-radius: 100px;
  background: #eb5a5a;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.green-streak-dot {
  width: 12.34px;
  height: 12.34px;

  border-radius: 100px;
  background: #b1d28b;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.grey-streak-dot {
  width: 12.34px;
  height: 12.34px;

  border-radius: 100px;
  background: rgba(0, 0, 0, 0.2);
  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.day-indicator {
  text-transform: uppercase;
  font-family: "Poppins";
  font-weight: 600;
}

.period-container {
  display: flex !important;
  flex-direction: column !important;
  text-align: center;
  row-gap: 60px;
  color: white;
}

.insight-stats-container {
  color: white;
  display: flex;
  flex-direction: row !important;
  column-gap: 15px;
  align-items: center;
  text-align: center;
  justify-content: center;
  row-gap: 10px;
}

.sensory-insight-stats-container {
  color: white;
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  overflow-x: scroll;
}

.single-progress-container {
  height: 100px;
  width: 100px;
}

.mood-tag {
  padding-top: 15px;
  text-transform: capitalize;
}

.sense-tag {
  padding-top: 5px;
  text-transform: capitalize;
}

.slick-dots li.slick-active button:before {
  color: white !important;
}

.single-sense {
  background: linear-gradient(86.16deg,
      rgba(255, 255, 255, 0.2) 11.14%,
      rgba(255, 255, 255, 0.035) 113.29%);
  box-shadow: 0px 1.34976px 33.7439px rgba(69, 42, 124, 0.1),
    inset 11.2799px 11.2799px 32.7117px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(39.4797px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 18.0479px;
  padding: 15px;
  width: 100px;
}

.profile-box-divider {
  height: 0.5px;
  width: 90%;
  border: 1px solid rgba(255, 255, 255, 0.37);
  align-self: center;
}

.profile-left-content {
  width: 25%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.profile-right-content {
  width: 25%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.streak-container {
  display: flex;
  /* flex-direction:; */
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

.streak-text {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.streak-current {
  font-size: 18px;
  font-weight: 500;
}

.streak-remaining {
  font-size: 12px;
  font-weight: 400;
}

.name-tag {
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
}

.location-tag {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: 0.01em;
}

.avatar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 15px;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 5px;
}

.profile-avatar-image {
  object-fit: cover;
  height: 140px;
  width: 140px;
  border-radius: 130px;
  border: 2px solid #ffffff;
}

.profile-avatar {
  position: relative;
  /* padding-top: 20px; */
  display: inline-block;
}

.badge {
  position: absolute;
  right: 5px;
  bottom: 5px;
  text-align: center;
  background: linear-gradient(258.32deg, #8ed9f9 -14.13%, #b275fe 97.09%);
  border: 2px solid #ffffff;
  border-radius: 24px;
  color: white;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 15px;
}

.loading-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 90%;
  /* background-color: red; */
  column-gap: 20px;
  padding: 10px;
  row-gap: 20px;
}

@media screen and (max-width: 750px) {
  .profile-container {
    flex-direction: column;
  }

  .profile-left-content {
    width: 100%;
  }

  .profile-main-content {
    width: 100%;
  }

  .profile-right-content {
    width: 100%;
  }

  .single-progress-container {
    height: 50px;
    width: 50px;
  }

  .mood-stat-icon {
    width: 25px;
    height: 25px;
  }

  .mood-tag {
    font-size: 12px;
  }
}

.btn-back-profile {
  font-family: "Poppins";
  font-style: normal;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0px 30px;
  column-gap: 5px;
}

.btn-back-manage {
  font-family: "Poppins";
  font-style: normal;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  padding: 0px 30px;
}

.expTileMain {
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: space-between;
}

.expTilesContainer {
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-height: 80vh;
  overflow-y: auto;
}

.expTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.timeanddurationtext {
  display: flex;
  column-gap: 10px;
}

.titleandsubtitle {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.bottomExp {
  display: flex;
  column-gap: 10px;
  align-items: center;
  font-size: 14px;
}

.verticalDivider {
  width: 1px;
  border: 1px solid #ffffff20;
  height: 24px;
  background-color: "white";
  margin: "0 8px";
}

.rowGapCon {
  display: flex;
  flex-direction: row;
  /* border: 1px solid #ff0000; */
  font-size: 14px;
  width: 100%;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
}

.header-row-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 1pc;
  padding-bottom: 1pc;
}

.profileButtonContainer {
  display: flex;
  flex: 0 1 auto;
  margin-left: auto;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
}

.logoutButtonContainer {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
}

.manage-subs{
  /* background-color: red; */
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.manage-link{
  color: white;
  text-decoration: underline;
}