@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.major-heading-experience {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin: auto;
}

.back {
    align-self: start;
    font-family: 'Poppins';
    font-style: normal;
    color: #ffffff;
    font-weight: 500;
    font-size: 25px;
    line-height: 37px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    column-gap: 10px;
    padding: 30px;
}

.back:hover {
    opacity: 0.8;
}

.choose-experience-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 2%;
    outline: none;
}

.login-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 2%;
    outline: none;
}

.subscribe-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between !important;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    outline: none;
}

.next-button,
.next-button-contact {
    background: #FFFFFF;
    border-radius: 50px;
    border: 0px;
    width: 250px;
    height: 50px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 120%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: black;
    cursor: pointer;
}

.next-button:hover,
.next-button-contact:hover {
    opacity: 0.8;
}

.next-button:disabled,
.next-button-contact:disabled {
    opacity: 0.5;
}

.time-box {
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 1pc;
    justify-content: center;
    align-items: center;
    width: 70%;
}

.time-row {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    justify-content: center;
    align-items: center;
}

.time-btn {
    height: 54px;
    width: 127px;
    border: none;
    padding: 0 25px 0 25px;
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    backdrop-filter: blur(25.9976px);
    border-top: 2px solid rgba(225, 225, 225, 0.3);
    border-bottom: 1px solid rgba(225, 225, 225, 0.3);
    border-left: 2px solid rgba(225, 225, 225, 0.1);
    border-right: 1px solid rgba(225, 225, 225, 0.2);
    border-radius: 9.90385px;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 18px;
    line-height: 18px;
    font-weight: 300;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

.time-btn:hover {
    background-color: white;
    border-radius: 9.90385px;
    color: black;
    font-weight: 500;
}

.time-btn-click {
    height: 54px;
    width: 127px;
    padding: 0 25px 0 25px;
    background-color: white;
    border-radius: 9.90385px;
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    color: black;
    cursor: pointer;
    font-weight: 500;
}

.choose-time-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    text-align: center;
}

.choose-breathwork-subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    text-align: center;
    padding-top: 1pc;
}

.experience-btn-container {
    margin: auto;
}

.breath-card-container {
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 1pc;
}

.breath-card {
    /* background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    /* border: 4px solid transparent; */
    border-radius: 24px;
    width: 285px;
    height: 250px;
    margin-top: 2%;
    cursor: pointer;
    position: relative;
}

.breath-card-active {
    /* background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border: 4px solid white;
    border-radius: 24px;
    width: 285px;
    height: 250px;
    margin-top: 2%;
    cursor: pointer;
    position: relative;
}

.breath-card.active .breath-card-inner {
    transform: rotateY(180deg);
   /* backface-visibility: hidden; */
}

.breath-card.active .breath-card-back {
    display: flex !important;
    flex-direction: column;
}

.breath-card-active.active .breath-card-back {
    display: flex !important;
    flex-direction: column;
}

.breath-card-active.active .breath-card-inner {
    transform: rotateY(180deg);
    /* backface-visibility: hidden; */
}

.breath-card-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100% !important;
    transition: transform 0.5s;
    transform-style: preserve-3d;
    align-items: center;
    pointer-events: none;
    backface-visibility: hidden;
}

.breath-card-front{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    width: 100%;
    height: 100%;
}
.breath-card-back {
    position: absolute;
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    width: 100%;
    /* padding: 0 1pc 0 2pc; */
    /* background-color: rgba(255, 255, 255, 0.3); */
    /* backdrop-filter: blur(7px); */
    /* -webkit-backdrop-filter: blur(7px); */
    /* background: linear-gradient(0deg, rgba(103, 73, 73, 0.3), rgba(0, 0, 0, 0.3)); */
    border-radius: 24px;
    height: 100%;
    justify-content: center;
}

.breath-card-back-blur {
    position: absolute;
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    /* background: linear-gradient(0deg, rgba(103, 73, 73, 0.3), rgba(0, 0, 0, 0.3)); */
    width: 100%;
    /* padding: 0 1pc 0 2pc; */
    filter: blur(7px);
    border-radius: 24px;
    height: 100%;
    justify-content: center;
}

.breath-card-back-body {
    background: linear-gradient(0deg, rgba(103, 73, 73, 0.3), rgba(0, 0, 0, 0.3));
    font-family: 'Poppins';
    font-style: normal;
    font-size: 12px;
    line-height: 150%;
    display: flex;
    flex-direction: column;
    display: absolute;
    align-items: left;
    /* justify-content: left; */
    text-align: left;
    color: #FFFFFF;
    z-index: 100;
    height: 100%;
    justify-content: center;
    border-radius: 24px;
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
}

@supports (not(-webkit-touch-callout: none)) and (not(translate:none)) {

    .time-btn,
    .time-btn-click {
        margin-left: 0.5pc;
        margin-right: 0.5pc;
    }

    .breath-card,
    .breath-card-active {
        margin-left: 0.5pc;
        margin-right: 0.5pc;
    }

    @media screen and (min-width: 640px) {
        .breath-card-container {
            overflow-y: auto;
            overflow-x: hidden;
        }

    }

    @media screen and (max-width: 786px) {

        .breath-card,
        .breath-card-active {
            margin-bottom: 1pc;
            margin-top: 1pc;
            margin-right: 0;
            margin-left: 0;
        }
    }
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .breath-card-back-blur {
        filter: blur(0px);
    }

    .breath-card-back-body {
        backdrop-filter: blur(7px);
        -webkit-backdrop-filter: blur(7px);
    }
}

.breath-card-front {
    -webkit-backface-visibility: hidden;
    /* Safari */
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
}

.breath-card-back {
    -webkit-backface-visibility: hidden;
    /* Safari */
    backface-visibility: hidden;
    display: none;
}


.breath-card:hover {
    border-color: #FFFFFF;
}

.touchpad-card {
    margin-top: 2%;
}

@media screen and (max-width: 786px) {

    .breath-card-container {
        flex-direction: column;
        row-gap: 1pc;
        margin-top: 2pc;
    }
}

@media screen and (max-width: 640px) {
    .major-heading-experience {
        font-size: 32px;
        line-height: 28px;
    }

    .choose-experience-container,
    .login-container {
        margin-bottom: 10%;
    }

    .choose-time-heading,
    .choose-breathwork-subtitle {
        font-size: 15px;
    }

    .time-btn {
        font-size: 12px;
        height: 40px;
        width: 100px;
        padding: 0 20px 0 20px;
    }

    .time-btn:hover {
        padding: 0 20px 0 20px;
    }

    .time-btn-click {
        font-size: 12px;
        height: 40px;
        width: 100px;
        padding: 0 20px 0 20px;
    }

    .touchpad-card div {
        width: 220px !important;
        height: 200px !important;
    }

    .breath-card,
    .breath-card-active {
        width: 280px !important;
        height: 260px !important;
    }
}

@media screen and (max-width: 480px) {
    .major-heading-experience {
        font-size: 24px;
        line-height: 24px;
    }

    .time-btn {
        font-size: 12px;
        height: 40px;
        width: 70px;
        padding: 0 8px 0 8px;
    }

    .time-btn:hover {
        padding: 0 8px 0 8px;
    }

    .time-btn-click {
        height: 40px;
        font-size: 12px;
        width: 70px;
        padding: 0 8px 0 8px;
    }

    .next-button,
    .next-button-contact {
        width: 190px;
    }

    .choose-breathwork-subtitle {
        width: 30vh;
    }
}

@media screen and (max-width: 330px) {
    .time-btn {
        font-size: 10px;
        height: 40px;
        width: 60px;
        padding: 0 8px 0 8px;
    }

    .time-btn:hover {
        padding: 0 8px 0 8px;
    }

    .time-btn-click {
        height: 40px;
        font-size: 10px;
        width: 60px;
        padding: 0 8px 0 8px;
    }
}