@import url("https://fonts.googleapis.com/css2?family=Poppins");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");

.container {
  /* padding: 0 2rem; */
  position: absolute;
  z-index: 100;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
  background-blend-mode: screen;
  backdrop-filter: blur(40px);
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 20px;
  border: white;
}

.logout {
  align-self: start;
  font-family: "Poppins";
  font-style: normal;
  color: #ffffff;
  font-weight: 500;
  font-size: 20px;
  line-height: 37px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  cursor: pointer;
  column-gap: 10px;
  padding: 30px;
}

.welcome-heading,
.welcome-heading-complete {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  display: flex;
  flex-basis: 10%;
  align-items: flex-end;
  text-align: center;
  color: #ffffff;
  padding-bottom: 0.5pc;
  column-gap: 15px;
  padding-top: 1pc;
  /* margin-top: 20px; */
}

.checkmark {
  width: 30px;
  height: 31px;
}

.welcome-subheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 148%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.welcome-subtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 142%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  /* padding-top: 3pc; */
  padding-top: 1pc;
}

.learn-more {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 60%;
  color: #ffffff;
  /* margin: auto; */
  padding-top: 1pc;
  padding-bottom: 1pc;
  padding-left: 2pc;
  padding-right: 2pc;
}

.web-version {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  padding-top: 1pc;
  padding-left: 2pc;
  padding-right: 2pc;
}

.logo {
  height: 70px;
  width: 180px;
  position: relative;
  top: 37px;
  padding-bottom: 1pc;
}

.subscribelogo {
  height: 70px;
  width: 180px;
  top: 37px;
  padding-bottom: 1pc;
}

.logoNew {
  flex: 0 1 auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
}


.logoProfile {
  height: 50px;
}

.box-row {
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  column-gap: 5%;
  width: 70%;
}

.box-row:focus-visible {
  outline: none;
}

.focus-box {
  position: relative;
  background: linear-gradient(90deg, #8b56e0 0%, #b780f0 50%, #e3aaff 100%),
    #ffffff;
  /* backdrop-filter: blur(50px); */
  border-radius: 15.478px;
  flex-basis: 100%;
  padding: 1% 0 5% 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
  row-gap: 10px;
}

.focus-box:hover {
  opacity: 0.8;
}

.relax-box {
  position: relative;
  background: linear-gradient(224.86deg, #296edf 10%, #c5e6fa 100%), #ffffff;
  border-radius: 14.5227px;
  flex-basis: 100%;
  padding: 1% 0 5% 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  row-gap: 10px;
}

.relax-box:hover {
  opacity: 0.8;
}

.focus-box-gradient {
  position: absolute;
  top: 0;
  left: 0;
}

.focus-box-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100% !important;
  justify-content: center;
  transition: transform 0.8s;
  -webkit-transition: transform 0.8s;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  align-items: center;
  row-gap: 1pc;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

/* .focus-box.active, .relax-box.active{
  padding: 1% 0 1% 0;
} */

.focus-box.active .focus-box-inner {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  width: 80%;
  /* justify-content: start; */
}

.relax-box.active .focus-box-inner {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  width: 80%;
  /* justify-content: start; */
}

.focus-box-front,
.focus-box-back {
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
  perspective: 0;
  -webkit-perspective: 0;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  visibility: visible;
}

.focus-box-back {
  position: absolute;
  transform: rotateY(180deg) translateZ(1px);
  -webkit-transform: rotateY(180deg) translateZ(1px);
}

.focus-box-back-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 157%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffffff;
}

.focus-box-back-body {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  display: absolute;
  align-items: left;
  justify-content: left;
  text-align: left;
  color: #ffffff;
}

.info-icon,
.info-icon-zense {
  /* right: 1pc; */
  height: 25px;
  /* top: 1pc; */
  /* position: absolute; */
  margin-right: 1pc;
  align-self: flex-end;
  cursor: pointer;
  z-index: 900;
}

.info-icon-breath {
  right: 1pc;
  height: 20px;
  top: 1pc;
  position: absolute;
  /* margin-right: 1pc; */
  align-self: flex-end;
  cursor: pointer;
  z-index: 900;
}

.focus-box-subheading {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  width: 300px;
}

.focus-box-vector {
  width: 100px;
  height: 100px;
}

@supports (not (-webkit-touch-callout: none)) and (not (translate: none)) {
  @media screen and (min-width: 1024px) {
    .focus-box {
      margin-right: 1pc;
    }

    .relax-box {
      margin-left: 1pc;
    }
  }

  @media screen and (max-width: 786px) {
    .focus-box {
      margin-bottom: 1pc;
      margin-right: 0;
    }

    .relax-box {
      margin-top: 1pc;
      margin-left: 0;
    }

    .focus-box-vector {
      margin-right: 1pc;
    }
  }
}

@media screen and (max-width: 1300px) and (min-width: 1024px) {

  .focus-box.active,
  .relax-box.active {
    overflow: auto;
  }
}

@media screen and (max-width: 1200px) {
  .box-row {
    width: 80%;
  }

  .focus-box,
  .relax-box {
    font-size: 40px;
    line-height: 40px;
    min-height: 260px;
  }

  /* .focus-box.active, .relax-box.active{
    min-height: 320px;
  } */

  .focus-box-subheading {
    width: 280px;
    font-size: 18px;
    line-height: 30px;
  }

  .focus-box-vector {
    width: 90px;
    height: 90px;
  }

  .focus-box-back-body {
    font-size: 12px;
  }

  .learn-more {
    width: 80%;
  }
}

@media screen and (max-width: 840px) and (min-width: 768px) {

  .focus-box.active,
  .relax-box.active {
    min-height: 280px;
  }
}

@media screen and (max-width: 786px) {
  .logo {
    height: 50px;
    width: 130px;
  }

  .box-row {
    margin-top: 3%;
    display: flex;
    flex-direction: column !important;
    row-gap: 5%;
    height: 100%;
    width: 80% !important;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 3%;
  }

  .focus-box,
  .relax-box {
    flex-basis: 100%;
    width: 90% !important;
    height: auto !important;
    min-height: 210px;
    padding-top: 4%;
    align-items: flex-start;
    padding-left: 5%;
    /* padding-right: 3%; */
    /* padding: 10%; */
  }

  .focus-box.active,
  .relax-box.active {
    height: 100%;
    min-height: 190px;
    padding-top: 2% !important;
    align-items: center;
    padding-left: 0;
    /* overflow: auto; */
  }

  .info-icon-zense {
    position: absolute;
    top: 1pc;
  }

  .focus-box-front {
    display: flex;
    flex-direction: row;
    column-gap: 2pc;
    margin-right: 0.5pc;
  }

  .focus-box-front-headings {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .focus-box-subheading {
    text-align: start;
  }

  .welcome-heading {
    width: auto;
    font-size: 25px;
    line-height: 25px;
    padding-left: 3%;
    padding-right: 3%;
  }

  .checkmark {
    width: 25x;
    height: 25px;
  }

  .welcome-subheading {
    font-size: 17px;
    padding-left: 3%;
    padding-right: 3%;
  }

  .welcome-subtitle {
    font-size: 25px;
    padding-left: 3%;
    padding-right: 3%;
  }

  /* .focus-box-vector {
    width: 80px;
    height: 80px;
  } */
}

@media screen and (max-width: 640px) {
  .welcome-heading {
    font-size: 22px;
    column-gap: 10px;
    flex-basis: 5%;
  }

  .welcome-heading-complete {
    font-size: 22px;
    column-gap: 10px;
    display: inline-block;
    flex-basis: 5%;
  }

  .checkmark {
    width: 22px;
    height: 22px;
    display: inline-block;
    margin-left: 10px;
  }

  .focus-box-vector {
    width: 80px;
    height: 80px;
  }

  .focus-box,
  .relax-box {
    font-size: 30px;
    line-height: 35px;
    min-height: 150px;
    /* padding: 3% 0 3% 0; */
  }

  .focus-box.active,
  .relax-box.active {
    /* min-height: 250px; */
    padding: 3% 0 3% 0;
  }

  .focus-box-subheading,
  .relax-box-subheading {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    width: auto;
  }

  .welcome-subheading {
    font-size: 12px;
    padding-top: 0.5pc;
  }

  .welcome-subtitle {
    font-size: 17px;
    padding-top: 0.5pc;
  }

  .focus-box-back-heading {
    font-size: 27px;
  }

  /* .focus-box-back-body {
    font-size: 15px;
  } */
}

@media screen and (max-width: 480px) {

  .welcome-heading,
  .welcome-heading-complete {
    font-size: 17px;
  }

  .welcome-subtitle {
    font-size: 15px;
  }

  .learn-more {
    font-size: 10px;
  }

  .focus-box,
  .relax-box {
    font-size: 30px;
    line-height: 30px;
    min-height: 140px;
  }

  .focus-box.active,
  .relax-box.active {
    overflow: auto;
    min-height: 180px;
    padding: 5% 0 5% 0;
  }

  .focus-box-front {
    column-gap: 1pc;
  }

  .focus-box-subheading,
  .relax-box-subheading {
    font-weight: 500;
    font-size: 12px;
    line-height: 27px;
  }

  .focus-box-back-heading {
    font-size: 25px;
  }

  .focus-box-back-body {
    font-size: 10px;
  }

  .focus-box-vector {
    width: 50px;
    height: 50px;
  }

  .focus-box-gradient {
    width: 200px;
    height: 200px;
  }

  .info-icon,
  .info-icon-zense {
    height: 20px;
  }

  .focus-box.active .info-icon {
    margin-top: 0.5pc;
  }

  .relax-box.active .info-icon {
    margin-top: 0.5pc;
  }

  .close,
  .back {
    flex-direction: column !important;
  }

  /* .close {
    padding-right: 10px;
  }

  .back {
    padding-left: 10px !important;
  } */
}

.profileButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
}

.btn-menu {
  font-family: "Poppins";
  font-style: normal;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  padding: 0px 30px;
}